import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
// import { Buffer } from 'buffer';
declare const Buffer


export function patternValidator(regexp: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        const value = control.value;
        if (!value) {
            return null;
        }
        return !regexp.test(value) ? { 'patternInvalid': { regexp } } : null;
    };
}

export function validationError(form) {
    Object.keys(form.controls).forEach(field => {
        const control = form.get(field);
        console.log(field, control.invalid)
        control.markAsTouched({ onlySelf: true });
        if (control.controls) {
            validationError(control);
        }
    });
}




export function clean(obj) {
    for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
            delete obj[propName];
        }
        if (typeof obj[propName] == 'object' && !(obj[propName] instanceof Date)) {
            obj[propName] = clean(obj[propName]);
            if (Object.keys(obj[propName]).length == 0) {
                delete obj[propName];
            }
        }
    }

    return obj;
}


export function getDayName() {
    return ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
}

export function getMonthName() {
    return ["January", "February", "March", "April", "May", "June", "July",
        "August", "September", "October", "November", "December"]
}

export function getFinanceMonthName() {
    return ["April", "May", "June", "July",
        "August", "September", "October", "November", "December", "January", "February", "March",]
}


export function getTodayHours() {
    let startofDay: any = moment().startOf('day').format("hh:mm A");
    let timesArray: any = [];
    for (let i = 0; i < 24; i++) {
        let value = moment(startofDay, 'hh:mm A').format('HH');
        timesArray.push({ name: startofDay, value: value });
        startofDay = moment(startofDay, 'hh:mm A').add(60, 'minutes').format("hh A");
    }

    return timesArray;
}


export function getNextDate() {
    let date = moment();
    let nextDate = moment(date).add(1, 'days').format('DD-MM-YY HH:mm')
    return nextDate;
}

export function sortByDate(data) {
    return data.sort((a, b) => {
        if (a < b) {            // a comes first
            return -1
        } else if (b < a) {     // b comes first
            return 1
        } else {                // equal, so order is irrelevant
            return 0            // note: sort is not necessarily stable in JS
        }
    })
}


export const defaultPagination = {
    page: 1,
    limit: 20
}

export function base64(data) {
    if (Object.keys(data).length > 0) {
        let objJsonStr = JSON.stringify(data);
        let objJsonB64 = Buffer.from(objJsonStr).toString("base64");
        return objJsonB64;
    }
    else {
        return null;
    }

}

export function getModules() {
    let modules = JSON.parse(localStorage.getItem('modules'));
    return modules;
}

export function checkPermission(permission) {
    let modules = getModules();
    // console.log(modules)
    if (modules.indexOf(permission) >= 0) {
        return true;
    }
    else if (modules.indexOf(`${permission}.y`) != -1) {
        return true;
    }
    else {
        return false;
    }
}


export function fillDataInForm(form, data) {
    for (let control of Object.keys(form.controls)) {
        if (data[control]) {
            form.controls[control].setValue(data[control]);
        }
    }
}

export const allStatus = {
    // current: ['PLACED',
    //     // 'ACCEPTED', 'CONFIRMED',
    //     'SHIPPED', 'PAYMENT_CONFIRMED'],
    // past: ['DELIVERED',
    //     // 'PENDING',
    //     // 'CANCELLED',
    //     // 'RETURN',
    //     // 'REJECTED',
    //     'INCOMPLETE',]
    current: ['PLACED', 'PAYMENT_CONFIRMED', 'ACCEPTED', 'FOOD_READY', 'SHIPPED', 'DELIVERED', 'CANCELLED'],
    past: ['PLACED', 'PAYMENT_CONFIRMED', 'ACCEPTED', 'FOOD_READY', 'SHIPPED', 'DELIVERED', 'CANCELLED'],

 
}

export const orderColors = ['#E2FBF8', '#FFF9DD', '#D0E7EF', '#FEEBEB', '#ECF0FD', '#FAEFDC'];

export const allSchemeTypes = [{
    name: 'Points Multiplier',
    fields: ['productId', 'pointTimes'],
},
{
    name: 'Couple Discounts',
    fields: ['productId', 'relatedProductId', 'discountPercentage'],
},
{
    name: 'Points Added',
    fields: ['additionalPoints', 'requiredAmount', 'relatedProductId'],
},
{
    name: 'Overall Free',
    fields: ['requiredAmount', 'relatedProductId'],
}, {
    name: 'Quantity Discount',
    fields: ['productId', 'relatedProductId', 'requiredQuantity', 'discountAmount', 'discountPercentage'],
},
{
    name: 'One Rupee',
    fields: ['requiredAmount', 'relatedProductId'],
},
{
    name: 'First User',
    fields: ['users', 'productId'],
},
{
    name: 'Reward on amount',
    fields: [],
}]

export const roles = [
    // { role: 'Select Role', val: '' },
    { role: 'mr', val: 'mr' },
    { role: 'retailer', val: 'retailer' },
    { role: 'stockist', val: 'stockist' },
    { role: 'distributor', val: 'distributor' }
];


export function formatImages(images) {
    if (images && images.length > 0) {
        let formattedIamges = JSON.parse(JSON.stringify(images));
        for (let image of formattedIamges) {
            delete image.signedUrl;
        }
        return formattedIamges;
    }
    else {
        return [];
    }
}

// export function fillImages(control, images, signedUrl) {
//     let formattedImages = [];
//     images.forEach((image, index) => {
//         let imageObj = {};
//         imageObj = {
//             url: image.url,
//             // signedUrl: signedUrl[index] ? signedUrl[index].url : ''
//         }
//         if (image.isPrimary) {
//             imageObj['isPrimary'] = image.isPrimary;
//         }
//         formattedImages.push(imageObj);
//     });
//     control.setValue(formattedImages);
// }
export function fillImages(control, images) {
    let formattedImages = [];
    images.forEach(image => {
        let imageObj: any = {
            url: image.url
        };
        if (image.isPrimary !== undefined) {
            imageObj.isPrimary = image.isPrimary;
        }
        formattedImages.push(imageObj);
    });
    control.setValue(formattedImages);
}

