import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'primary'
})
export class PrimaryPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (value && value.length > 0) {
      console.log(value);
      let image = value.find(element => {return element.isPrimary});
      if (image) {
        return image.url;
      }
      else {
        return value[0].url;
      }
    }
    return null;
  }

}
